// Footer Accordion
if ($(window).width() < 960) {
    $("#accordion h5").click(function () {
        if (false == $(this).next().is(':visible')) {
            $('#accordion ul').slideUp(300);
            $('#accordion h5').removeClass('active');
        }
        $(this).next().slideToggle(300);
        $(this).toggleClass('active');
    });
    $('#accordion ul:eq(0)').show();
    $('#accordion h5:eq(0)').addClass('active');
};

$(function () {
    $('.row').each(function (i, elem) {
        $(elem)
            .find('.creative-section__content') // Only children of this row
            .matchHeight({
                byRow: false
            }); // Row detection gets confused so disable it
    });
});

$(document).ready(function () {

    $('.timeline .owl-carousel').owlCarousel({
            loop: false,
            margin: 0,
            nav: false,
            responsive: {
                0: {
                    items: 1
                }
            }
        }).find('.owl-item')
        .each(function (i) {
            var attr = $(this).children().attr('data-year');
            var element = $('<span>' + attr + '</span>');
            $('.timeline .owl-carousel .owl-dot').eq(i).append(element);
        });




    $('.carousel').carousel();

    $('.offer-popup').magnificPopup({
        // type: 'inline',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function () {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });
    $("#autoUpdate").hide();
    $('#checkbox1').change(function () {
        if (this.checked) {
            $('#autoUpdate').show(300);
        } else {
            $('#autoUpdate').hide(200);
        }
    });

    $('#lightboxMoveFromTop').magnificPopup({
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function () {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });

    // Materialize Starts Here
    M.updateTextFields();
    $('select').formSelect();
    $('.datepicker').datepicker();
    $('.timepicker').timepicker();


    // Materialize Ends Here
    // 360 Color change functions
    $('.color-choose input').on('click', function () {
        var headphonesColor = $(this).attr('data-image');
        $('.active').removeClass('active');
        $('.left-column img[data-image = ' + headphonesColor + ']').addClass('active');
        $(this).addClass('active');
    });
    $('.exterior__360_action button').on('click', function () {
        $('.exterior__360_action button.current').removeClass('current');
        $(this).addClass('current');
    });
    // 360 Color change functions ends
    // Specifications Tabs
    $('ul.tabs li').click(function () {
        var tab_id = $(this).attr('data-tab');
        $('ul.tabs li').removeClass('current');
        $('.tab-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    });
    // magnificPopup
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

    // scroll to top
    var scrollTop = $(".scrollTop");
    $(window).scroll(function () {
        var topPos = $(this).scrollTop();
        if (topPos > 100) {
            $(scrollTop).css("opacity", "1");
        } else {
            $(scrollTop).css("opacity", "0");
        }
    });
    $(scrollTop).click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
    // scroll to top Ends
});
// Slick Slider Starts
$('.mainbanner').slick({
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    arrows: false
});
$('.sales-slider').slick({
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    arrows: false
});


$('.cars-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});
// Slick Slider Ends

// Menu Script starts here !!!
// Menu Script Ends here !!!
// Photo Gallery Starts here !!!
$('.gallery-link').on('click', function () {
    $(this).next().magnificPopup('open');
});
$('.gallery').each(function () {
    $(this).magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true,
            navigateByImgClick: true
        },
        fixedContentPos: false
    });
});
// Photo Gallery Ends here !!!
$(".car-colors span").click(function () {
    $(".car-colors span").removeClass("active");
    $(this).addClass("active");
    $(".car-button").css("color", $(this).attr("data-color"));
    $(".car-pic").css("background-image", $(this).attr("data-pic"));
});

$('#cs35-white').click(function () {
    $('#cs35').reel('images', 'images/360/cs35/white/####.png');
});
$('#cs35-red').click(function () {
    $('#cs35').reel('images', 'images/360/cs35/red/####.png');
});
$('#cs35-blue').click(function () {
    $('#cs35').reel('images', 'images/360/cs35/blue/####.png');
});
$('#cs35-grey').click(function () {
    $('#cs35').reel('images', 'images/360/cs35/grey/####.png');
});
$('#cs35-brown').click(function () {
    $('#cs35').reel('images', 'images/360/cs35/brown/####.png');
});
$.reel.def.indicator = 5;


// car details accordion

$(".cardetails-accordion .car-details__spec_head").click(function () {
    if (false == $(this).next().is(':visible')) {
        $('.cardetails-accordion .cardetails').slideUp(300);
        $('.car-details__spec_head').removeClass('active');
    }
    $(this).next().slideToggle(300);
    $(this).toggleClass('active');
});
$('.cardetails-accordion .cardetails:eq(0)').addClass('active');
$('.car-details__spec_head:eq(0)').addClass('active');


(function ($) {

    // Accepts arguments as strings
    $calculator = $('#widget').loanCalculator({
        loanAmount: 'AED 1,000.00',
        loanDuration: '12',
        valueAddedTax: '0',
        serviceFee: '0',
        paymentFrequency: 'monthly'
    });

})(jQuery);


$(function () {
    var $el = $('.background__paralax');
    $(window).on('scroll', function () {
        var scroll = $(document).scrollTop();
        $el.css({
            'background-position': '50% ' + (-.06 * scroll) + 'px'
        });
    });
});





const menu = document.querySelector('.mobile-menu');

menu.addEventListener('click', function () {
    if (menu.classList.contains('open')) {
        menu.classList.remove('open');
        menu.classList.add('close');
    } else {
        menu.classList.remove('close');
        menu.classList.add('open');
    }
})



var nonLinearSlider = document.getElementById('pricerange');

noUiSlider.create(nonLinearSlider, {
    connect: true,
    behaviour: 'tap',
    start: [5, 15],
    step: 1,
    range: {
        // Starting at 500, step the value by 500,
        // until 4000 is reached. From there, step by 1000.
        'min': [1],
        'max': [20]
    }
});

var nodes = [
    document.getElementById('lower-value'), // 0
    document.getElementById('upper-value') // 1
];

// Display the slider value and how far the handle moved
// from the left edge of the slider.
nonLinearSlider.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
    nodes[handle].innerHTML = values[handle] + 'k';
});